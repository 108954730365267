@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
label {
  font-family: sans-serif;
}

html,
body {
  scrollbar-gutter: stable;
  scrollbar-width: thin;
  scrollbar-gutter: none;
  scrollbar-color: gray transparent;
}
